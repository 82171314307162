import { Card, CardContent, Box } from "@mui/material";
import React from "react";
import styles from "./ProductCard.module.scss";
import { Link } from "react-router-dom";
import Quantity from "../Quantity";

const ProductCard = (props) => {
  return (
    <Card
      style={{ boxShadow: "none", width: "100%", ...props.sty }}
      sx={{ height: "100%" }}
      className={`${styles.card} card`}
    >
      <CardContent className={styles.cardContent}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <p className={styles.category}>{props.data.Code}</p>
          <Link
            to={`/products/${props.data.ID}`}
            className={styles.title}
            style={{ textAlign: "center", marginTop: "15px", marginBottom: "15px", height: "45px" }}
          >
            {props.data.Description}
          </Link>

          <p className={styles.extra_description} style={{ textAlign: "center", marginTop: "15px", marginBottom: "15px", height: "80px" }}>{props.data.ExtraDescription}</p>

          <p
            style={props?.children && { color: "#000" }}
            className={styles.price}
          >
            {props?.children && props.children}
          </p>
        </Box>
        <Box
          component="div"
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              width: "60%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Quantity id={props.data.ID} sku={props.data.Code} />
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};

export default ProductCard;
